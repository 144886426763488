body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 20px;
}

.App {
  max-width: 800px;
  margin: 0 auto;
}

form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

input[type="number"],
select {
  width: 100px;
  padding: 5px;
  margin-right: 10px;
}

button {
  padding: 10px 20px;
  background-color: #f76e2a;
  color: white;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #d75819;
}